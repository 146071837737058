<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="30%"
    @close="closeHandle"
  >
    <div class="handle-content">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="rules"
        class="demo-form-inline"
      >
        <el-form-item label="接收人姓名" :label-width="'120px'" prop="name">
          <el-input v-model="dataForm.name" placeholder="接收人姓名" clearable :style="{ width: '315px' }"/>
        </el-form-item>
        
        <el-form-item
          label="接收人手机号"
          :label-width="'120px'"
          prop="mobile"
        >
          <el-input v-model="dataForm.mobile" placeholder="接收人手机号" clearable :style="{ width: '315px' }"/>
        </el-form-item>
        <el-form-item
          label="发送类型"
          :label-width="'120px'"
          prop="channels"
        >
          <el-select
            v-model="dataForm.channels"
            placeholder="请选择"
            :style="{ width: '315px' }"
            multiple
            @change="$forceUpdate()"
          >
            <el-option label="短信" :value="'1'"></el-option>
            <el-option label="语音呼叫" :value="'2'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="信息类型"
          :label-width="'120px'"
          prop="types"
        >
          <el-select
            v-model="dataForm.types"
            placeholder="请选择"
            :style="{ width: '315px' }"
            multiple
            @change="$forceUpdate()"
          >
            <el-option label="故障" :value="'1'"></el-option>
            <el-option label="低电量" :value="'2'"></el-option>
            <el-option label="离线" :value="'3'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="信息等级"
          :label-width="'120px'"
          prop="level"
        >
          <el-select
            v-model="dataForm.level"
            placeholder="请选择"
            filterable
            :style="{ width: '315px' }"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="一级" :value="1"></el-option>
            <el-option label="二级" :value="2"></el-option>
            <el-option label="三级" :value="3"></el-option>
            <el-option label="四级" :value="4"></el-option>
            <el-option label="五级" :value="5"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button type="primary" :loading="loading" @click="dataFormSubmitHandle()">确认</el-button>
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import axios from "@/utils/axios";
export default {
  data() {
    return {
      title: null,
      loading: false,
      dataForm: {
        id: null,
        mobile: null,
        name: null,
        type: null,
        level: null,
        status: 1,
        types: null,
        orgId: null,
        channel: null,
        channels: null
      },
      visible: false,
    };
  },
  computed: {
    rules() {
      return {
        name: [
          { required: true, message: "必填项不能为空", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "必填项不能为空", trigger: "blur" },
          { validator: this.validatePhoneNumber, trigger: 'blur' }
        ],
        level: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        types: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        channels: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ]
      };
    },
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.dataForm.orgId = localStorage.getItem('organId')
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if(this.dataForm.id) {
          this.getDataInfo()
        }
      });
    },
    closeHandle() {
      this.$refs["dataForm"].resetFields();
      this.dataForm = {
        id: null,
        mobile: null,
        name: null,
        type: null,
        level: null,
        status: 1,
        types: null,
        orgId: null,
        channel: null,
        channels: null
      }
    },
    getDataInfo(){
      axios.post(`/api/msg/get`, {id: this.dataForm.id}).then(res => {
        this.dataForm = {...res}
        this.dataForm.types = res.type.split(',')
        this.dataForm.channels = res.channel.split(',')
      })
    },

    changeHandle() {
      let types = this.dataForm.types
      this.dataForm.type = types.join(',')
    },
    changeTypeHandle() {
      let channels = this.dataForm.channels
      this.dataForm.channel = channels.join(',')
    },
    validatePhoneNumber(rule, value, callback) {
      if (/^1[3|4|5|7|8][0-9]{9}$/.test(value)) {
        callback();
      } else {
        callback(new Error('请输入合法的手机号'));
      }
    },
    dataFormSubmitHandle() {
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        let {dataForm} = this
        let channels = dataForm.channels
        dataForm.channel = channels.join(',')
        let types = dataForm.types
        dataForm.type = types.join(',')
        if(dataForm.id) {
          axios
          .post(`/api/msg/update`, dataForm)
          .then(res => {
            if (res) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            this.loading = false;
            return this.$message.error(err);
          });
        } else {
          axios
          .post(`/api/msg/add`, dataForm)
          .then(res => {
            if (res) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            this.loading = false;
            return this.$message.error(err);
          });
        }
        
      });
    },
  },
};
</script>
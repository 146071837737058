<template>
  <div class="basic-container">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="消息设置" name="first"> -->
        <el-button class="add-button" @click="addHandle">新增</el-button>
        <el-table
          class="mt20 ft14 c333 it1"
          style="width: 100%"
          height="500px"
          highlight-current-row
          stripe
          :data="list"
          key="MenuList"
          v-loading="loading"
        >
          <el-table-column prop="loginBy" label="接收人姓名">
            <template slot-scope="scope">
              {{ scope.row.name || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="orgName" label="接收人手机号">
            <template slot-scope="scope">
              {{ scope.row.mobile || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="platformName" label="消息类别">
            <template  slot-scope="scope">
              {{ scope.row.channel | channelStr}}
            </template>
          </el-table-column>
          <el-table-column prop="loginTime" label="信息类别">
            <template slot-scope="scope">
              {{ scope.row.type | typeStr}}
            </template>
          </el-table-column>
          <el-table-column prop="loginTime" label="信息等级">
            <template slot-scope="scope">
              {{ scope.row.level | levelStr }}
            </template>
          </el-table-column>
          <el-table-column prop="loginTime" label="更新时间">
            <template slot-scope="scope">
              {{ scope.row.updateTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="loginTime" label="操作">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status === 0" @click="setHandle(scope.row)" type="text"
                >启用</el-button
              >
              <el-button v-if="scope.row.status === 1" @click="setHandle(scope.row)" type="text"
                >停用</el-button
              >
              <el-button @click="editHandle(scope.row)" type="text"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="sflex-pe page-container">
          <div class="table-page-container">
            <el-pagination
              background
              :current-page="search.pageIndex"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      <!-- </el-tab-pane>
      <el-tab-pane label="其他" name="second"></el-tab-pane>
    </el-tabs> -->
    <AddModel v-if="visable" ref="addmodel" @refreshDataList="getData" />
  </div>
</template>

<script>
import "./Index.less";
import axios from "@/utils/axios";
import AddModel from "./components/AddModel";
export default {
  name: "BasicSettings",
  data() {
    return {
      activeName: "first",
      visable: false,
      loading: false,
      total: 0,
      search: {
        pageIndex: 1,
        pageSize: 20,
        orgId: null,
      },
      list: [],
    };
  },
  components: {
    AddModel,
  },
  mounted() {
    this.search.orgId = localStorage.getItem("organId");
    this.getData()
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name;
    },
    //获取列表数据
    getData() {
      this.loading = true;
      axios
        .post(`/api/msg/list`, this.search)
        .then((res) => {
          const { list, total } = res;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    //新增
    addHandle() {
      this.visable = true;
      this.$nextTick(() => {
        this.$refs.addmodel.init();
        this.$refs.addmodel.title = '新增消息';
      });
    },
    //编辑
    editHandle(row) {
      this.visable = true;
      this.$nextTick(() => {
        this.$refs.addmodel.init();
        this.$refs.addmodel.title = '编辑消息';
        this.$refs.addmodel.dataForm.id = row.id
      });
    },
    setHandle(row) {
      let status = null
      if(row.status === 1) {
        status = 0
      } else {
        status = 1
      }
      axios
        .post(`/api/msg/update`, {id: row.id, status: status})
        .then((res) => {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 500,
          });
          this.getData()
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.search.pageIndex = val;
      this.getData();
    },
  },
  filters: {
    typeStr(value) {
      let array = value.split(',')
      if(array.length === 1) {
        if(array[0] === '1') {
          return '故障'
        } else if(array[0] === '2') {
          return '低电量'
        } else if(array[0] === '3') {
          return '离线'
        }
      } else if(array.length === 2) {
        let str1 = ''
        let str2 = ''
        if(array[0] === '1') {
          str1 = '故障'
        } else if(array[0] === '2') {
          str1 = '低电量'
        } else if(array[0] === '3') {
          str1 = '离线'
        }
        if(array[1] === '1') {
          str2 = '故障'
        } else if(array[1] === '2') {
          str2 = '低电量'
        } else if(array[1] === '3') {
          str2 = '离线'
        }
        return str1 + '/' + str2
      } else {
        let str1 = ''
        let str2 = ''
        let str3 = ''
        if(array[0] === '1') {
          str1 = '故障'
        } else if(array[0] === '2') {
          str1 = '低电量'
        } else if(array[0] === '3') {
          str1 = '离线'
        }
        if(array[1] === '1') {
          str2 = '故障'
        } else if(array[1] === '2') {
          str2 = '低电量'
        } else if(array[1] === '3') {
          str2 = '离线'
        }
        if(array[2] === '1') {
          str3 = '故障'
        } else if(array[2] === '2') {
          str3 = '低电量'
        } else if(array[2] === '3') {
          str3 = '离线'
        }
        return str1 + '/' + str2 + '/' + str3
      }
    },
    channelStr(value) {
      let array = value.split(',')
      if(array.length === 1) {
        if(array[0] === '1') {
          return '短信'
        } else if(array[0] === '2') {
          return '语音呼叫'
        }
      } else if(array.length === 2) {
        let str1 = ''
        let str2 = ''
        if(array[0] === '1') {
          str1 = '短信'
        } else if(array[0] === '2') {
          str1 = '语音呼叫'
        }
        if(array[1] === '1') {
          str2 = '短信'
        } else if(array[1] === '2') {
          str2 = '语音呼叫'
        }
        return str1 + '/' + str2
      }
    },
    levelStr(value) {
      if(value !== null || value !== undefined) {
        if(value === 0) {
          return '全部'
        } else if(value === 1) {
          return '>=1级'
        } else if(value === 2) {
          return '>=2级'
        } else if(value === 3) {
          return '>=3级'
        } else if(value === 4) {
          return '>=4级'
        } else {
          return '5级'
        }
      }
    }
  }
};
</script>